import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { graphql } from "gatsby"
import { Global, css } from "@emotion/core"
import { SEO, Wrapper, Header } from "../components"
import { LocaleContext } from "../components/Layout"

const Hero = styled.header`
  background-color: ${props => props.theme.colors.bg};
  padding-top: 1rem;
  padding-bottom: 4rem;
  h1 {
    color: ${props => props.theme.colors.black};
  }
`

const LocaleSwitcherStyle = theme => css`
  [data-name="locale-switcher"] {
    color: ${theme.colors.greyBlue};
    a {
      color: white;
    }
  }
`

const CatWrapper = Wrapper.withComponent("main")

const Categories = ({
  location,
  data: { categories },
  pageContext: { locale },
}) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]

  return (
    <>
      <Global styles={LocaleSwitcherStyle} />
      <SEO
        title={`${i18n.about} | ${i18n.defaultTitleAlt}`}
        pathname={location.pathname}
        locale={locale}
      />
      <Hero>
        <Wrapper>
          <Header invert />
          <h1>Page Not Found</h1>
        </Wrapper>
      </Hero>
      <CatWrapper>
        <p>Oops, we couldn't find this page!</p>
      </CatWrapper>
    </>
  )
}

export default Categories

export const pageQuery = graphql`
  query allHeroLinks2 {
    allPrismicHeroLinks {
      edges {
        node {
          id
        }
      }
    }
  }
`
